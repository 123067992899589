$trans: all 0.6s ease;

/****  Colors ****/
$brand: #38a9ff;
$sec: #c2ff1f;
$text: #9a9da2;
$light-grey: #edeff4;

/****  Fonts ****/
$ff: 'Source Sans Pro', sans-serif;
$ff-sec:  'Montserrat', sans-serif;
